@charset "UTF-8";
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
@import url("//fonts.googleapis.com/css?family=Raleway%3A100%2C300%2C400%2C500%2C600");
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*
 * Columnizer
 */
body {
  font-family: "Raleway", serif; }
  body.fonts-loading {
    font-family: serif; }

.section-slides section > h1, .callout .callout__wrapper .callout__featured-text p strong, .copy .intro__wrapper h1, .form h1, .section-slides section .section__intro h1, .section-slides section .section__half-box h1, .callout .callout__wrapper .callout__featured-text p, .copy .copy__wrapper h1, .section-slides section .info-box h1, .featured-image .featured-image__wrapper div h1, .accordion .accordion__wrapper > ul > li .accordion__title h1, .classes .classes__wrapper div > h1, .portal .portal__wrapper h1, .classes .classes__wrapper .classes__listing .class h2, .header__wrapper nav .nav__wrapper > a {
  font-family: "Raleway", sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: auto; }

.section-slides section .section__intro p, .classes .classes__wrapper div > p, .copy .intro__wrapper p, body, .form .form__wrapper .freeform-row .freeform-column label, .trainer-bio p, .featured-image .featured-image__wrapper div p, .classes .classes__wrapper .classes__listing .class .class__info p, .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper p, .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper h1, .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper p, .section-slides section [href='#view-all'], .become-a-member, figure figcaption, .section-slides section .details-box, .header__wrapper nav .nav__info, .header__wrapper nav .nav__hours {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: auto; }

.footer {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: auto; }

.section-slides.section--titled .section__nav li, .sub-navigation, .callout .callout__wrapper > div > p, header:before, .form .tabs, .section-slides section .details-box [href='#show-hide'], .header__wrapper nav .nav__meta a {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: auto; }

.trainer__info .info__content h1, .trainers .trainers__wrapper .trainers__listing .trainer h1, .toggle-bio, .classes .classes__wrapper .classes__listing .class h1, .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper h1, .button, .section-slides section .details-box [href='#show-hide'] {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: auto; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0; }
  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  p:last-child {
    margin-bottom: 0; }

h1 {
  margin-bottom: 2.5rem; }

h2 {
  margin-bottom: 1.5rem; }

p {
  margin-bottom: 1.1rem; }

.section-slides section > h1, .callout .callout__wrapper .callout__featured-text p strong {
  font-size: 9rem;
  line-height: 8.5rem; }
  @media (max-width: 768px) {
    .section-slides section > h1, .callout .callout__wrapper .callout__featured-text p strong {
      font-size: 8rem;
      line-height: 7.5rem; } }
  @media (max-width: 768px) {
    .section-slides section > h1, .callout .callout__wrapper .callout__featured-text p strong {
      font-size: 7rem;
      line-height: 6.6rem; } }

.copy .intro__wrapper h1, .form h1, .section-slides section .section__intro h1, .section-slides section .section__half-box h1, .callout .callout__wrapper .callout__featured-text p {
  font-size: 5.5rem;
  line-height: 6.6rem;
  margin-bottom: 2.5rem; }
  @media (max-width: 768px) {
    .copy .intro__wrapper h1, .form h1, .section-slides section .section__intro h1, .section-slides section .section__half-box h1, .callout .callout__wrapper .callout__featured-text p {
      font-size: 3rem;
      line-height: 3.4rem;
      margin-bottom: 20px; } }

.copy .copy__wrapper h1, .section-slides section .info-box h1, .featured-image .featured-image__wrapper div h1, .accordion .accordion__wrapper > ul > li .accordion__title h1, .classes .classes__wrapper div > h1, .portal .portal__wrapper h1 {
  font-size: 3.2rem;
  line-height: 4rem;
  margin-bottom: 1.5rem; }
  @media (max-width: 768px) {
    .copy .copy__wrapper h1, .section-slides section .info-box h1, .featured-image .featured-image__wrapper div h1, .accordion .accordion__wrapper > ul > li .accordion__title h1, .classes .classes__wrapper div > h1, .portal .portal__wrapper h1 {
      font-size: 2.1rem;
      line-height: 2.5rem; } }

.section-slides section .section__intro p, .classes .classes__wrapper div > p {
  font-size: 2.4rem;
  line-height: 3.6rem; }

.trainer__info .info__content h1, .trainers .trainers__wrapper .trainers__listing .trainer h1, .toggle-bio, .classes .classes__wrapper .classes__listing .class h1, .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper h1 {
  letter-spacing: 0.14em;
  font-size: 1.6rem;
  line-height: 3rem;
  text-transform: uppercase; }

.classes .classes__wrapper .classes__listing .class h2 {
  font-size: 3.2rem; }

.copy .intro__wrapper p {
  font-size: 2.4rem;
  line-height: 3.4rem; }
  @media (max-width: 768px) {
    .copy .intro__wrapper p {
      font-size: 1.5rem;
      line-height: 2.1rem; } }

body, .form .form__wrapper .freeform-row .freeform-column label, .trainer-bio p, .featured-image .featured-image__wrapper div p, .classes .classes__wrapper .classes__listing .class .class__info p, .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper p, .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper h1 {
  font-size: 1.6rem;
  line-height: 3rem; }
  @media (max-width: 768px) {
    body, .form .form__wrapper .freeform-row .freeform-column label, .trainer-bio p, .featured-image .featured-image__wrapper div p, .classes .classes__wrapper .classes__listing .class .class__info p, .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper p, .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper h1 {
      font-size: 1.5rem;
      line-height: 2.1rem; } }

.portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper p {
  font-size: 1.3rem;
  line-height: 3rem; }

.section-slides.section--titled .section__nav li, .sub-navigation, .callout .callout__wrapper > div > p {
  letter-spacing: 0.3em;
  font-size: 1rem;
  line-height: 2rem; }

.button {
  letter-spacing: 0.14em;
  font-size: 1rem;
  line-height: 3rem;
  text-transform: uppercase; }

.section-slides section [href='#view-all'], .become-a-member {
  letter-spacing: 0.3em;
  font-size: 1rem;
  line-height: 3rem;
  text-transform: uppercase; }

header:before, .form .tabs, .section-slides section .details-box [href='#show-hide'] {
  letter-spacing: 0.3em;
  font-size: 1rem;
  line-height: 3rem;
  text-transform: uppercase; }

.header__wrapper nav .nav__wrapper > a {
  font-size: 6rem;
  line-height: 6rem; }
  @media (max-width: 768px) {
    .header__wrapper nav .nav__wrapper > a {
      font-size: 5.25rem;
      line-height: 5.25rem; } }
  @media (max-width: 768px) {
    .header__wrapper nav .nav__wrapper > a {
      font-size: 4.5rem;
      line-height: 4.5rem; } }

.header__wrapper nav .nav__meta a {
  letter-spacing: 0.3em;
  font-size: 1rem;
  line-height: 3.2rem;
  text-transform: uppercase; }

figure figcaption, .section-slides section .details-box {
  font-size: 1.2rem;
  line-height: 2rem; }

.header__wrapper nav .nav__info, .header__wrapper nav .nav__hours {
  font-size: 1.2rem;
  line-height: 1.8rem; }

.footer {
  font-size: 1.2rem;
  line-height: 1.4rem; }

@font-face {
  font-family: 'fontello';
  src: url("../fonts/fontello.eot?31078938");
  src: url("../fonts/fontello.eot?31078938#iefix") format("embedded-opentype"), url("../fonts/fontello.woff2?31078938") format("woff2"), url("../fonts/fontello.woff?31078938") format("woff"), url("../fonts/fontello.ttf?31078938") format("truetype"), url("../fonts/fontello.svg?31078938#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.svg?31078938#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
/* '' */
html {
  font-size: 62.5%;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased; }

::selection {
  color: #ffffff;
  background: #f85650; }

a[x-apple-data-detectors] {
  font-family: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  text-decoration: none !important;
  color: inherit !important; }

main {
  position: relative;
  min-height: 100vh;
  min-height: calc(100vh - var(--vh-offset, 0px));
  margin-left: 110px; }
  @media (max-width: 768px) {
    main {
      margin-left: 0; } }

.copy .intro__wrapper, .copy .copy__wrapper, .trainers .trainers__wrapper, .sub-navigation, .sub-navigation .sub-navigation--filter, .accordion, .classes .classes__wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  box-sizing: border-box; }
  .copy .intro__wrapper::after, .copy .copy__wrapper::after, .trainers .trainers__wrapper::after, .sub-navigation::after, .sub-navigation .sub-navigation--filter::after, .accordion::after, .classes .classes__wrapper::after {
    clear: both;
    content: "";
    display: block; }
  .copy .intro__wrapper > *:not(style):not(script), .copy .copy__wrapper > *:not(style):not(script), .trainers .trainers__wrapper > *:not(style):not(script), .sub-navigation > *:not(style):not(script), .sub-navigation .sub-navigation--filter > *:not(style):not(script), .accordion > *:not(style):not(script), .classes .classes__wrapper > *:not(style):not(script) {
    box-sizing: border-box; }
    .copy .intro__wrapper > *:not(style):not(script):nth-child(1n+1), .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1), .trainers .trainers__wrapper > *:not(style):not(script):nth-child(1n+1), .sub-navigation > *:not(style):not(script):nth-child(1n+1), .sub-navigation .sub-navigation--filter > *:not(style):not(script):nth-child(1n+1), .accordion > *:not(style):not(script):nth-child(1n+1), .classes .classes__wrapper > *:not(style):not(script):nth-child(1n+1) {
      width: calc(66.6666666667% - 50px);
      float: left;
      margin-left: 30px;
      left: calc(16.6666666667% - 35px + 30px);
      position: relative; }
    .copy .intro__wrapper > *:not(style):not(script):not(:nth-child(1n+1)), .copy .copy__wrapper > *:not(style):not(script):not(:nth-child(1n+1)), .trainers .trainers__wrapper > *:not(style):not(script):not(:nth-child(1n+1)), .sub-navigation > *:not(style):not(script):not(:nth-child(1n+1)), .sub-navigation .sub-navigation--filter > *:not(style):not(script):not(:nth-child(1n+1)), .accordion > *:not(style):not(script):not(:nth-child(1n+1)), .classes .classes__wrapper > *:not(style):not(script):not(:nth-child(1n+1)) {
      clear: none; }
    .copy .intro__wrapper > *:not(style):not(script):nth-child(1n+1), .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1), .trainers .trainers__wrapper > *:not(style):not(script):nth-child(1n+1), .sub-navigation > *:not(style):not(script):nth-child(1n+1), .sub-navigation .sub-navigation--filter > *:not(style):not(script):nth-child(1n+1), .accordion > *:not(style):not(script):nth-child(1n+1), .classes .classes__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }
  @media (max-width: 768px) {
    .copy .intro__wrapper, .copy .copy__wrapper, .trainers .trainers__wrapper, .sub-navigation, .sub-navigation .sub-navigation--filter, .accordion, .classes .classes__wrapper {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      box-sizing: border-box; }
      .copy .intro__wrapper::after, .copy .copy__wrapper::after, .trainers .trainers__wrapper::after, .sub-navigation::after, .sub-navigation .sub-navigation--filter::after, .accordion::after, .classes .classes__wrapper::after {
        clear: both;
        content: "";
        display: block; }
      .copy .intro__wrapper > *:not(style):not(script), .copy .copy__wrapper > *:not(style):not(script), .trainers .trainers__wrapper > *:not(style):not(script), .sub-navigation > *:not(style):not(script), .sub-navigation .sub-navigation--filter > *:not(style):not(script), .accordion > *:not(style):not(script), .classes .classes__wrapper > *:not(style):not(script) {
        box-sizing: border-box; }
        .copy .intro__wrapper > *:not(style):not(script):nth-child(1n+1), .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1), .trainers .trainers__wrapper > *:not(style):not(script):nth-child(1n+1), .sub-navigation > *:not(style):not(script):nth-child(1n+1), .sub-navigation .sub-navigation--filter > *:not(style):not(script):nth-child(1n+1), .accordion > *:not(style):not(script):nth-child(1n+1), .classes .classes__wrapper > *:not(style):not(script):nth-child(1n+1) {
          width: calc(83.3333333333% - 55px);
          float: left;
          margin-left: 30px;
          left: calc(8.3333333333% - 32.5px + 30px);
          position: relative; }
        .copy .intro__wrapper > *:not(style):not(script):not(:nth-child(1n+1)), .copy .copy__wrapper > *:not(style):not(script):not(:nth-child(1n+1)), .trainers .trainers__wrapper > *:not(style):not(script):not(:nth-child(1n+1)), .sub-navigation > *:not(style):not(script):not(:nth-child(1n+1)), .sub-navigation .sub-navigation--filter > *:not(style):not(script):not(:nth-child(1n+1)), .accordion > *:not(style):not(script):not(:nth-child(1n+1)), .classes .classes__wrapper > *:not(style):not(script):not(:nth-child(1n+1)) {
          clear: none; }
        .copy .intro__wrapper > *:not(style):not(script):nth-child(1n+1), .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1), .trainers .trainers__wrapper > *:not(style):not(script):nth-child(1n+1), .sub-navigation > *:not(style):not(script):nth-child(1n+1), .sub-navigation .sub-navigation--filter > *:not(style):not(script):nth-child(1n+1), .accordion > *:not(style):not(script):nth-child(1n+1), .classes .classes__wrapper > *:not(style):not(script):nth-child(1n+1) {
          clear: both; } }
  @media (max-width: 768px) {
    .copy .intro__wrapper, .copy .copy__wrapper, .trainers .trainers__wrapper, .sub-navigation, .sub-navigation .sub-navigation--filter, .accordion, .classes .classes__wrapper {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      box-sizing: border-box; }
      .copy .intro__wrapper::after, .copy .copy__wrapper::after, .trainers .trainers__wrapper::after, .sub-navigation::after, .sub-navigation .sub-navigation--filter::after, .accordion::after, .classes .classes__wrapper::after {
        clear: both;
        content: "";
        display: block; }
      .copy .intro__wrapper > *:not(style):not(script), .copy .copy__wrapper > *:not(style):not(script), .trainers .trainers__wrapper > *:not(style):not(script), .sub-navigation > *:not(style):not(script), .sub-navigation .sub-navigation--filter > *:not(style):not(script), .accordion > *:not(style):not(script), .classes .classes__wrapper > *:not(style):not(script) {
        box-sizing: border-box; }
        .copy .intro__wrapper > *:not(style):not(script):nth-child(1n+1), .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1), .trainers .trainers__wrapper > *:not(style):not(script):nth-child(1n+1), .sub-navigation > *:not(style):not(script):nth-child(1n+1), .sub-navigation .sub-navigation--filter > *:not(style):not(script):nth-child(1n+1), .accordion > *:not(style):not(script):nth-child(1n+1), .classes .classes__wrapper > *:not(style):not(script):nth-child(1n+1) {
          width: calc(100% - 60px);
          float: left;
          margin-left: 30px;
          left: calc(0% - 30px + 30px);
          position: relative; }
        .copy .intro__wrapper > *:not(style):not(script):not(:nth-child(1n+1)), .copy .copy__wrapper > *:not(style):not(script):not(:nth-child(1n+1)), .trainers .trainers__wrapper > *:not(style):not(script):not(:nth-child(1n+1)), .sub-navigation > *:not(style):not(script):not(:nth-child(1n+1)), .sub-navigation .sub-navigation--filter > *:not(style):not(script):not(:nth-child(1n+1)), .accordion > *:not(style):not(script):not(:nth-child(1n+1)), .classes .classes__wrapper > *:not(style):not(script):not(:nth-child(1n+1)) {
          clear: none; }
        .copy .intro__wrapper > *:not(style):not(script):nth-child(1n+1), .copy .copy__wrapper > *:not(style):not(script):nth-child(1n+1), .trainers .trainers__wrapper > *:not(style):not(script):nth-child(1n+1), .sub-navigation > *:not(style):not(script):nth-child(1n+1), .sub-navigation .sub-navigation--filter > *:not(style):not(script):nth-child(1n+1), .accordion > *:not(style):not(script):nth-child(1n+1), .classes .classes__wrapper > *:not(style):not(script):nth-child(1n+1) {
          clear: both; } }

figure {
  line-height: 0;
  margin: 0; }

img {
  max-width: 100%;
  height: auto; }

.map {
  height: 341px; }

a {
  list-style-type: none;
  text-decoration: none; }

ul {
  list-style-type: none; }

/* Safari only */
_:-webkit-full-screen:not(:root:root),
* {
  font-family: 'Raleway',sans-serif; }

.footer {
  padding-right: 16px;
  padding-left: 16px;
  position: relative;
  min-height: 50px;
  margin-left: 110px;
  text-align: center;
  color: #ffffff;
  background-image: url("/assets/images/wood-dark.jpg");
  background-size: cover; }
  @media (max-width: 1024px) {
    .footer {
      margin-left: 0; } }
  .footer:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: rgba(51, 51, 51, 0.6); }
  .footer .footer__wrapper {
    padding-top: 18px;
    padding-bottom: 18px;
    position: relative; }
    .footer .footer__wrapper .footer__address {
      display: inline-block;
      padding-right: 6px; }
    .footer .footer__wrapper .footer__container {
      display: inline-block; }
    .footer .footer__wrapper a {
      font-family: inherit !important;
      font-size: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
      padding-right: 6px;
      padding-left: 6px;
      text-decoration: none !important;
      color: inherit !important; }

section .footer {
  margin-left: 0; }

main + .footer.absolute {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0; }

header {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 110px;
  background-color: #ffffff; }
  @media (max-width: 768px) {
    header {
      top: auto;
      right: 0;
      width: auto; } }
  header .header__wrapper {
    line-height: 0; }
    header .header__wrapper > [href='/'] {
      display: block; }
      @media (max-width: 768px) {
        header .header__wrapper > [href='/'] {
          display: inline-block; } }
  header:before {
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 600;
    line-height: 110px;
    z-index: 1;
    box-sizing: border-box;
    width: 25vh;
    height: 110px;
    padding-right: 72px;
    content: attr(page-title);
    transform: translateX(110px) rotateZ(-90deg);
    transform-origin: bottom left;
    text-align: right; }
    @media (max-width: 768px) {
      header:before {
        display: none; } }
  header:after {
    position: absolute;
    top: 75%;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-image: url("/assets/images/wood-light-vertical.jpg"); }
    @media (max-width: 768px) {
      header:after {
        display: none; } }
  header .header__logo {
    margin: 25px; }
    @media (max-width: 768px) {
      header .header__logo {
        margin: 8px 12px;
        height: 48px; } }
  header .menu-toggle {
    margin-top: 25%;
    margin-right: 31px;
    margin-left: 31px;
    padding: 14px 10px;
    width: 28px;
    height: 14px;
    cursor: pointer; }
    header .menu-toggle:hover > div > span:nth-child(3), header .menu-toggle:hover > div > span:nth-child(4) {
      width: 35%; }
    header .menu-toggle:hover > div > span:nth-child(3) {
      left: 15%; }
    header .menu-toggle:hover > div > span:nth-child(4) {
      right: 15%; }
    header .menu-toggle.active > div > span {
      width: 52.5%; }
      header .menu-toggle.active > div > span:nth-child(1), header .menu-toggle.active > div > span:nth-child(6) {
        transform: rotateZ(45deg); }
      header .menu-toggle.active > div > span:nth-child(2), header .menu-toggle.active > div > span:nth-child(5) {
        transform: rotateZ(-45deg); }
      header .menu-toggle.active > div > span:nth-child(1), header .menu-toggle.active > div > span:nth-child(2) {
        top: 1.5px; }
      header .menu-toggle.active > div > span:nth-child(5), header .menu-toggle.active > div > span:nth-child(6) {
        bottom: 1.5px; }
      header .menu-toggle.active > div > span:nth-child(3), header .menu-toggle.active > div > span:nth-child(4) {
        opacity: 0; }
      header .menu-toggle.active > div > span:nth-child(3) {
        left: -50%; }
      header .menu-toggle.active > div > span:nth-child(4) {
        right: -50%; }
      header .menu-toggle.active > div > span:nth-child(1), header .menu-toggle.active > div > span:nth-child(5) {
        left: 2px; }
      header .menu-toggle.active > div > span:nth-child(2), header .menu-toggle.active > div > span:nth-child(6) {
        right: 2px; }
    header .menu-toggle > div {
      position: relative;
      height: 100%; }
      header .menu-toggle > div > span {
        position: absolute;
        display: block;
        width: 50%;
        height: 2px;
        transition: all 250ms ease;
        opacity: 1;
        background-color: #817759; }
        header .menu-toggle > div > span:nth-child(1), header .menu-toggle > div > span:nth-child(3), header .menu-toggle > div > span:nth-child(5) {
          left: 0; }
        header .menu-toggle > div > span:nth-child(2), header .menu-toggle > div > span:nth-child(4), header .menu-toggle > div > span:nth-child(6) {
          right: 0; }
        header .menu-toggle > div > span:nth-child(1), header .menu-toggle > div > span:nth-child(2) {
          top: 0; }
        header .menu-toggle > div > span:nth-child(3), header .menu-toggle > div > span:nth-child(4) {
          top: 6px; }
        header .menu-toggle > div > span:nth-child(5), header .menu-toggle > div > span:nth-child(6) {
          bottom: 0; }
    @media (max-width: 768px) {
      header .menu-toggle {
        position: absolute;
        top: 50%;
        right: calc(50% - 31px);
        margin-top: 0;
        margin-bottom: 0;
        transform: translateY(-50%) translateX(50%); }
        header .menu-toggle > div > span:nth-child(3), header .menu-toggle > div > span:nth-child(4) {
          width: 36%; }
        header .menu-toggle > div > span:nth-child(3) {
          left: 15%; }
        header .menu-toggle > div > span:nth-child(4) {
          right: 15%; }
        header .menu-toggle:hover > div > span:nth-child(3), header .menu-toggle:hover > div > span:nth-child(4) {
          width: 36%; } }
  header .header__phone {
    display: none; }
    @media (max-width: 768px) {
      header .header__phone {
        position: absolute;
        top: 50%;
        right: 15%;
        display: block;
        transform: translateY(-50%);
        color: #333333; }
        header .header__phone:before {
          font-family: 'fontello';
          font-weight: normal;
          font-style: normal;
          font-variant: normal;
          line-height: 1;
          content: "";
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 2rem; } }

/* IE & Edge only */
_:-ms-lang(x),
.header__logo {
  max-width: 64%;
  margin-left: 20px !important; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  visibility: hidden;
  transition: all 250ms ease;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3); }
  .modal.show {
    visibility: visible;
    opacity: 1; }
  .modal .modal__close {
    position: absolute;
    top: -30px;
    right: 2px;
    z-index: 1;
    cursor: pointer;
    color: #f85650; }
    .modal .modal__close:before {
      font-family: 'fontello';
      font-weight: normal;
      font-style: normal;
      font-variant: normal;
      line-height: 1;
      content: "";
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .modal .modal__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .modal .modal__wrapper .modal__content {
      position: relative;
      width: 400px;
      max-width: calc(100% - 60px);
      max-height: 82vh;
      background-color: #373239;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.45); }
      @media (min-height: 780px) {
        .modal .modal__wrapper .modal__content {
          width: 500px; } }

.trainer__info {
  position: relative; }
  .trainer__info .info__content__wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 90%; }
  .trainer__info .info__content {
    position: relative;
    z-index: 1000;
    padding: 20px 20px 40px;
    background-color: #373239; }
    @media (min-width: 940px) {
      .trainer__info .info__content {
        padding: 20px 40px 40px; } }
    .trainer__info .info__content h1 {
      font-size: 2rem;
      margin-bottom: 8px;
      letter-spacing: 1.2px;
      color: #ffffff; }
    .trainer__info .info__content p {
      color: #ffffff; }
    .trainer__info .info__content .link__content {
      display: flex;
      margin-top: 30px;
      align-items: center;
      justify-content: space-between; }
    .trainer__info .info__content .email_link a {
      display: block; }
      .trainer__info .info__content .email_link a:before {
        display: block;
        width: 28px;
        height: 20px;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 28 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Subtract' fill-rule='evenodd' clip-rule='evenodd' d='M0.990569 1.51239C0.682322 1.92808 0.5 2.44275 0.5 3V18C0.5 19.3807 1.61929 20.5 3 20.5H25.5C26.8807 20.5 28 19.3807 28 18V3C28 2.44278 27.8177 1.92814 27.5095 1.51247L14.2501 13.7172L0.990569 1.51239ZM25.7377 0.511156C25.6595 0.503775 25.5802 0.5 25.5 0.5H3C2.91986 0.5 2.84061 0.503771 2.7624 0.511144L14.2501 11.0851L25.7377 0.511156Z' fill='%23F85851'/%3E%3C/svg%3E%0A"); }

.header__wrapper nav {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50vw;
  z-index: 999;
  display: flex;
  box-sizing: border-box;
  width: 50vw;
  height: 100vh;
  height: calc(100vh - var(--vh-offset, 0px));
  padding-left: 94px;
  transition: 250ms;
  background-color: #ffffff;
  align-items: center; }
  @media (max-width: 768px) {
    .header__wrapper nav {
      left: -75vw;
      width: 75vw;
      padding-left: 64px; } }
  @media (max-width: 768px) {
    .header__wrapper nav {
      padding-right: 24px;
      padding-left: 24px;
      position: fixed;
      left: -100vw;
      width: 100vw; } }
  @media (max-width: 768px) {
    .header__wrapper nav .nav__wrapper {
      position: absolute;
      top: 0;
      right: 16px;
      bottom: 24px;
      left: 16px;
      padding-top: 32px;
      padding-bottom: 32px;
      overflow: auto; }
      .header__wrapper nav .nav__wrapper:before, .header__wrapper nav .nav__wrapper:after {
        z-index: 1;
        display: none;
        height: 32px;
        content: ''; }
      .header__wrapper nav .nav__wrapper:before {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background-image: linear-gradient(#ffffff, rgba(255, 255, 255, 0)); }
      .header__wrapper nav .nav__wrapper:after {
        position: fixed;
        right: 0;
        bottom: 24px;
        left: 0;
        background-image: linear-gradient(rgba(255, 255, 255, 0), #ffffff); } }
  .header__wrapper nav .nav__wrapper > a {
    display: block;
    text-decoration: none; }
    @media (max-width: 768px) {
      .header__wrapper nav .nav__wrapper > a {
        font-size: 2.5rem;
        line-height: 3.5rem; } }
  .header__wrapper nav .nav__wrapper a {
    transition: 250ms;
    color: #000000; }
    .header__wrapper nav .nav__wrapper a:hover {
      color: #96b5aa; }
  .header__wrapper nav .nav__back {
    padding: 10px 6px 10px 12px;
    display: inline-block;
    margin-bottom: 64px;
    cursor: pointer; }
    @media (max-width: 768px) {
      .header__wrapper nav .nav__back {
        position: fixed;
        right: 50%;
        bottom: 0;
        z-index: 2;
        display: none;
        margin-bottom: 16px;
        transform: translateX(50%); } }
    .header__wrapper nav .nav__back span {
      position: relative;
      display: block;
      width: 16px;
      height: 2px;
      background-color: #cbcbcb; }
      .header__wrapper nav .nav__back span:before, .header__wrapper nav .nav__back span:after {
        position: absolute;
        left: -10px;
        width: 6px;
        height: 2px;
        content: '';
        background-color: #cbcbcb; }
      .header__wrapper nav .nav__back span:before {
        top: -2px;
        transform: rotateZ(-45deg); }
      .header__wrapper nav .nav__back span:after {
        top: 2px;
        transform: rotateZ(45deg); }
    .header__wrapper nav .nav__back:hover span {
      background-color: #96b5aa; }
      .header__wrapper nav .nav__back:hover span:before, .header__wrapper nav .nav__back:hover span:after {
        background-color: #96b5aa; }
  .header__wrapper nav .nav__meta {
    margin-top: 50px; }
    @media (max-width: 768px) {
      .header__wrapper nav .nav__meta {
        margin-top: 16px; } }
    .header__wrapper nav .nav__meta a {
      display: block; }
  .header__wrapper nav .nav__social a {
    display: inline-block;
    margin-top: 7px;
    margin-right: 20px;
    color: #96b5aa; }
    .header__wrapper nav .nav__social a:hover {
      color: #b1dee6; }
    .header__wrapper nav .nav__social a.facebookicon:before {
      font-family: 'fontello';
      font-weight: normal;
      font-style: normal;
      font-variant: normal;
      line-height: 1;
      content: "";
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .header__wrapper nav .nav__social a.twittericon:before {
      font-family: 'fontello';
      font-weight: normal;
      font-style: normal;
      font-variant: normal;
      line-height: 1;
      content: "";
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .header__wrapper nav .nav__social a.instagramicon:before {
      font-family: 'fontello';
      font-weight: normal;
      font-style: normal;
      font-variant: normal;
      line-height: 1;
      content: "";
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .header__wrapper nav .nav__info {
    margin-top: 110px;
    padding-bottom: 10px; }
    @media (max-width: 768px) {
      .header__wrapper nav .nav__info {
        margin-top: 16px; } }
  .header__wrapper nav .nav__hours {
    padding-top: 10px;
    border-top: 2px solid #e5e5e5; }
  .header__wrapper nav + .nav-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    content: '';
    transition: 250ms;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.8); }
  .header__wrapper nav.active {
    left: 0; }
    .header__wrapper nav.active + .nav-overlay {
      visibility: visible;
      opacity: 1; }
    .header__wrapper nav.active .nav__wrapper:before, .header__wrapper nav.active .nav__wrapper:after,
    .header__wrapper nav.active .nav__wrapper .nav__back {
      display: block; }
  .header__wrapper nav:before, .header__wrapper nav:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 35px;
    content: ''; }
    @media (max-width: 768px) {
      .header__wrapper nav:before, .header__wrapper nav:after {
        display: none; } }
  .header__wrapper nav:before {
    background-image: url("/assets/images/wood-dark-vertical.jpg");
    background-size: cover; }
  .header__wrapper nav:after {
    background-color: rgba(51, 51, 51, 0.6); }

.content_wrapper .trainer__info .info__header > div div:nth-child(2) {
  position: absolute;
  bottom: 7%; }

.content_wrapper .trainer__info .info__header > div a {
  color: #ffffff; }
  .content_wrapper .trainer__info .info__header > div a:not(:last-child) {
    margin-right: 20px; }

.content_wrapper .trainer__info .info__header > div .email:before {
  font-family: 'fontello';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  content: "";
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.content_wrapper .trainer__info .info__header > div .facebook:before {
  font-family: 'fontello';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  content: "";
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.content_wrapper .trainer__info .info__header > div .twitter:before {
  font-family: 'fontello';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  content: "";
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.copy {
  padding-top: 75px;
  padding-bottom: 75px; }
  .copy:first-child {
    padding-top: 130px; }
    @media (max-width: 768px) {
      .copy:first-child {
        padding-top: 30px;
        padding-bottom: 30px; } }
  .copy:last-child {
    padding-bottom: 130px; }
    @media (max-width: 768px) {
      .copy:last-child {
        padding-bottom: 20px; } }
  @media (max-width: 768px) {
    .copy {
      padding-top: 30px;
      padding-bottom: 30px; } }
  .copy a {
    color: #f85650; }
    .copy a:hover {
      color: rgba(248, 86, 80, 0.6); }
  .copy .intro__wrapper + .copy__wrapper {
    margin-top: 40px; }
    @media (max-width: 768px) {
      .copy .intro__wrapper + .copy__wrapper {
        margin-top: 15px; } }
  .copy.copy--side-headline .copy__wrapper {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    box-sizing: border-box; }
    .copy.copy--side-headline .copy__wrapper::after {
      clear: both;
      content: "";
      display: block; }
    .copy.copy--side-headline .copy__wrapper > *:not(style):not(script) {
      box-sizing: border-box; }
      .copy.copy--side-headline .copy__wrapper > *:not(style):not(script):nth-child(2n+1) {
        width: calc(25% - 37.5px);
        float: left;
        margin-left: 30px;
        left: calc(16.6666666667% - 35px + 30px);
        position: relative; }
      .copy.copy--side-headline .copy__wrapper > *:not(style):not(script):nth-child(2n+2) {
        width: calc(41.6666666667% - 42.5px);
        float: left;
        margin-left: 30px;
        left: calc(16.6666666667% - 35px + 30px);
        position: relative; }
      .copy.copy--side-headline .copy__wrapper > *:not(style):not(script):not(:nth-child(2n+1)) {
        clear: none; }
      .copy.copy--side-headline .copy__wrapper > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
    @media (max-width: 768px) {
      .copy.copy--side-headline .copy__wrapper {
        margin-right: auto;
        margin-left: auto;
        max-width: 1170px;
        box-sizing: border-box; }
        .copy.copy--side-headline .copy__wrapper::after {
          clear: both;
          content: "";
          display: block; }
        .copy.copy--side-headline .copy__wrapper > *:not(style):not(script) {
          box-sizing: border-box; }
          .copy.copy--side-headline .copy__wrapper > *:not(style):not(script):nth-child(2n+1) {
            width: calc(33.3333333333% - 40px);
            float: left;
            margin-left: 30px;
            left: calc(8.3333333333% - 32.5px + 30px);
            position: relative; }
          .copy.copy--side-headline .copy__wrapper > *:not(style):not(script):nth-child(2n+2) {
            width: calc(50% - 45px);
            float: left;
            margin-left: 30px;
            left: calc(8.3333333333% - 32.5px + 30px);
            position: relative; }
          .copy.copy--side-headline .copy__wrapper > *:not(style):not(script):not(:nth-child(2n+1)) {
            clear: none; }
          .copy.copy--side-headline .copy__wrapper > *:not(style):not(script):nth-child(2n+1) {
            clear: both; } }
    @media (max-width: 768px) {
      .copy.copy--side-headline .copy__wrapper {
        margin-right: auto;
        margin-left: auto;
        max-width: 1170px;
        box-sizing: border-box; }
        .copy.copy--side-headline .copy__wrapper::after {
          clear: both;
          content: "";
          display: block; }
        .copy.copy--side-headline .copy__wrapper > *:not(style):not(script) {
          box-sizing: border-box; }
          .copy.copy--side-headline .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
            width: calc(100% - 60px);
            float: left;
            margin-left: 30px;
            left: calc(0% - 30px + 30px);
            position: relative; }
          .copy.copy--side-headline .copy__wrapper > *:not(style):not(script):not(:nth-child(1n+1)) {
            clear: none; }
          .copy.copy--side-headline .copy__wrapper > *:not(style):not(script):nth-child(1n+1) {
            clear: both; } }
  .copy.copy--cyan {
    margin-bottom: 75px;
    background: #e1f4f6; }
    .copy.copy--cyan + .image {
      margin-top: -75px; }
    .copy.copy--cyan:last-child {
      margin-bottom: 0; }
    @media (max-width: 768px) {
      .copy.copy--cyan {
        margin-bottom: 50px; }
        .copy.copy--cyan + .image {
          margin-top: -50px; } }

.form {
  position: relative;
  padding-top: 122px;
  background-image: linear-gradient(90deg, #b1dee6, #ffe5a4); }
  @media (max-width: 768px) {
    .form {
      padding-top: 22px; } }
  .form:after {
    position: absolute;
    top: 160px;
    right: 0;
    bottom: 10px;
    left: 0;
    content: '';
    background-image: url("/assets/images/wood-light.jpg");
    background-position: center; }
    @media (max-width: 768px) {
      .form:after {
        top: 77px; } }
  .form .form__wrapper {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    box-sizing: border-box;
    position: relative;
    display: flex; }
    .form .form__wrapper::after {
      clear: both;
      content: "";
      display: block; }
    .form .form__wrapper > *:not(style):not(script) {
      box-sizing: border-box; }
      .form .form__wrapper > *:not(style):not(script):nth-child(2n+1) {
        width: calc(37.5% - 41.25px);
        float: left;
        margin-left: 30px;
        left: calc(16.6666666667% - 35px + 30px);
        position: relative; }
      .form .form__wrapper > *:not(style):not(script):nth-child(2n+2) {
        width: calc(58.3333333333% - 47.5px);
        float: left;
        margin-left: 30px;
        left: calc(8.3333333333% - 32.5px + 30px);
        position: relative; }
      .form .form__wrapper > *:not(style):not(script):not(:nth-child(2n+1)) {
        clear: none; }
      .form .form__wrapper > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
    .form .form__wrapper div:nth-child(1) {
      z-index: 2; }
    .form .form__wrapper div:nth-child(2) {
      z-index: 1; }
    @media (max-width: 768px) {
      .form .form__wrapper {
        margin-right: auto;
        margin-left: auto;
        max-width: 1170px;
        box-sizing: border-box;
        flex-direction: column;
        width: 83%;
        align-items: center; }
        .form .form__wrapper::after {
          clear: both;
          content: "";
          display: block; }
        .form .form__wrapper > *:not(style):not(script) {
          box-sizing: border-box; }
          .form .form__wrapper > *:not(style):not(script):nth-child(1n+1) {
            width: calc(100% - 60px);
            float: left;
            margin-left: 30px; }
          .form .form__wrapper > *:not(style):not(script):not(:nth-child(1n+1)) {
            clear: none; }
          .form .form__wrapper > *:not(style):not(script):nth-child(1n+1) {
            clear: both; }
        .form .form__wrapper h1 {
          width: 103%;
          margin-left: 0; } }
    @media (max-width: 1024px) {
      .form .form__wrapper {
        margin-left: -67px; } }
    .form .form__wrapper form {
      padding: 55px 75px 1px;
      margin-top: -43px;
      margin-left: -96px;
      border: 10px solid #ffffff;
      border-bottom: hidden; }
      @media (max-width: 1024px) {
        .form .form__wrapper form {
          width: 75%;
          margin-left: -73px; } }
      @media (max-width: 768px) {
        .form .form__wrapper form {
          width: 100%;
          margin-left: -142px; } }
      @media (max-width: 768px) {
        .form .form__wrapper form {
          margin-left: 0;
          padding-right: 73px;
          padding-bottom: 96px;
          padding-left: 44px;
          border: none; } }
    .form .form__wrapper input {
      margin-bottom: 10px; }
    .form .form__wrapper textarea {
      overflow: hidden;
      height: 15%;
      resize: none; }
    .form .form__wrapper input,
    .form .form__wrapper textarea {
      border: none;
      border-bottom: 2px solid #000000;
      background: none; }
      .form .form__wrapper input:focus,
      .form .form__wrapper textarea:focus {
        resize: none;
        border-bottom: 2px solid #ffffff;
        outline: none; }
      @media (max-width: 768px) {
        .form .form__wrapper input,
        .form .form__wrapper textarea {
          border-bottom: 2px solid #b3b3b3; } }
    @media (max-width: 768px) {
      .form .form__wrapper .freeform-row {
        display: block; } }
    .form .form__wrapper .freeform-row:last-child {
      margin-bottom: 40px; }
      @media (max-width: 768px) {
        .form .form__wrapper .freeform-row:last-child {
          float: right;
          margin-top: 10px; } }
    .form .form__wrapper .freeform-row .freeform-column {
      display: inline-flex;
      align-items: end; }
      .form .form__wrapper .freeform-row .freeform-column #form-input-message {
        height: 22px; }
      .form .form__wrapper .freeform-row .freeform-column #form-input-email {
        margin-right: -5px;
        margin-left: 5px; }
      @media (max-width: 1024px) {
        .form .form__wrapper .freeform-row .freeform-column {
          margin-right: 0;
          margin-left: 0; }
          .form .form__wrapper .freeform-row .freeform-column #form-input-phone,
          .form .form__wrapper .freeform-row .freeform-column #form-input-email {
            width: 67%;
            padding-right: 16px; } }
      @media (max-width: 768px) {
        .form .form__wrapper .freeform-row .freeform-column {
          margin-right: 0;
          margin-left: 0; }
          .form .form__wrapper .freeform-row .freeform-column #form-input-phone {
            width: 54%; }
          .form .form__wrapper .freeform-row .freeform-column #form-input-email {
            width: 69%;
            margin-right: -10px;
            padding-right: 0; } }
      @media (max-width: 768px) {
        .form .form__wrapper .freeform-row .freeform-column {
          display: block; }
          .form .form__wrapper .freeform-row .freeform-column #form-input-phone,
          .form .form__wrapper .freeform-row .freeform-column #form-input-email {
            margin-right: 0;
            margin-left: 0;
            width: 100%; } }
      .form .form__wrapper .freeform-row .freeform-column label {
        margin-right: 15px; }
        @media (max-width: 768px) {
          .form .form__wrapper .freeform-row .freeform-column label {
            margin-right: 10px; } }
  .form .tabs {
    margin-top: 0;
    margin-bottom: 0;
    display: none;
    padding-left: 0;
    list-style: none; }
    .form .tabs .active {
      color: #6a7e91; }
  .form .button {
    letter-spacing: 0.3em;
    transition: 375ms ease-in;
    border: none;
    background-color: #ffffff;
    background-image: linear-gradient(90deg, rgba(177, 222, 230, 0), #b1dee6, #ffe5a4);
    background-position: -100% 0;
    background-size: 200% 100%; }
    .form .button:hover {
      transition-timing-function: ease-out;
      background-position: 100% 0;
      background-size: 200% 100%; }

.image.image--full img {
  width: 100%; }

.image.image--slider {
  overflow: hidden;
  white-space: nowrap; }
  .image.image--slider .image__slides {
    line-height: 0; }
  .image.image--slider figure {
    display: inline-block; }

.image + :not(.copy) {
  margin-top: 75px; }
  @media (max-width: 768px) {
    .image + :not(.copy) {
      margin-top: 40px; } }

.section-slides {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden; }
  @media (max-width: 768px) {
    .section-slides {
      bottom: 64px; } }
  .section-slides.section-slides--minus-footer {
    bottom: 50px; }
    @media (max-width: 768px) {
      .section-slides.section-slides--minus-footer {
        bottom: 64px; } }
    .section-slides.section-slides--minus-footer section .details-box {
      transform: translateY(-50%) translateY(-25px); }
      .section-slides.section-slides--minus-footer section .details-box.hide {
        transform: translateY(-50%) translateY(-25px) translateX(100%); }
      @media (max-width: 768px) {
        .section-slides.section-slides--minus-footer section .details-box {
          transform: translateY(-50%) translateY(7px); }
          .section-slides.section-slides--minus-footer section .details-box.hide {
            transform: translateY(-50%) translateY(7px) translateX(100%); } }
  .section-slides section {
    background-color: #ffffff; }
    @media (max-width: 768px) {
      .section-slides section.section--home, .section-slides section.section--billboard {
        display: flex;
        flex-direction: column; }
        .section-slides section.section--home .section__bg-image, .section-slides section.section--billboard .section__bg-image {
          background-repeat: no-repeat;
          background-size: cover;
          flex-grow: 1; }
      .section-slides section.section--home h1 {
        padding: 26px 50% 26px 32px;
        font-size: 3rem;
        line-height: 3.4rem;
        position: initial;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 0;
        background-color: #ffffff; }
      .section-slides section.section--billboard .section__intro {
        margin-right: auto;
        margin-left: auto;
        max-width: 1170px;
        box-sizing: border-box;
        padding-top: 24px;
        padding-bottom: 24px;
        margin-top: 0;
        color: #000000;
        background-color: #ffffff; }
        .section-slides section.section--billboard .section__intro::after {
          clear: both;
          content: "";
          display: block; }
        .section-slides section.section--billboard .section__intro > *:not(style):not(script) {
          box-sizing: border-box; }
          .section-slides section.section--billboard .section__intro > *:not(style):not(script):nth-child(1n+1) {
            width: calc(100% - 60px);
            float: left;
            margin-left: 30px; }
          .section-slides section.section--billboard .section__intro > *:not(style):not(script):not(:nth-child(1n+1)) {
            clear: none; }
          .section-slides section.section--billboard .section__intro > *:not(style):not(script):nth-child(1n+1) {
            clear: both; }
        .section-slides section.section--billboard .section__intro h1 {
          font-size: 3rem;
          line-height: 3.4rem; }
        .section-slides section.section--billboard .section__intro p {
          font-size: 1.5rem;
          line-height: 2.1rem; }
      .section-slides section.section--billboard .info-box {
        position: initial; }
      .section-slides section.section--slider {
        display: flex;
        flex-direction: column; }
        .section-slides section.section--slider .slider.slider--full {
          position: relative;
          flex-grow: 1; }
          .section-slides section.section--slider .slider.slider--full .slider__wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: auto; }
            .section-slides section.section--slider .slider.slider--full .slider__wrapper .slider__slides {
              height: 100%; }
              .section-slides section.section--slider .slider.slider--full .slider__wrapper .slider__slides figure {
                height: 100%; } }
  .section-slides .section__nav {
    position: absolute;
    top: 50%;
    right: 50px;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 10;
    padding-left: 0;
    list-style: none;
    transform: translateY(-50%);
    border-radius: 35px;
    background-color: rgba(0, 0, 0, 0.1); }
    @media (max-width: 768px) {
      .section-slides .section__nav {
        display: none; } }
    .section-slides .section__nav li {
      padding: 15px;
      cursor: pointer;
      transition: all 250ms ease; }
      .section-slides .section__nav li.active {
        padding: 12px; }
        .section-slides .section__nav li.active:before {
          width: 11px;
          height: 11px;
          background-color: transparent; }
      .section-slides .section__nav li:before {
        display: block;
        box-sizing: border-box;
        width: 5px;
        height: 5px;
        content: '';
        transition: all 250ms ease;
        border: 2px solid #ffffff;
        border-radius: 100%;
        background-color: #ffffff; }
  @media (max-width: 768px) {
    .section-slides.section--titled section {
      height: calc(100vh - 30px - 48px - 64px);
      height: calc(100vh - var(--vh-offset, 0px) - 30px - 48px - 64px); } }
  .section-slides.section--titled .section__nav {
    padding: 12px 10px;
    right: 0;
    border-radius: 0;
    background-color: #373239; }
    @media (max-width: 768px) {
      .section-slides.section--titled .section__nav {
        top: auto;
        right: 0;
        bottom: -25px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-around; } }
    .section-slides.section--titled .section__nav li {
      padding: 0 10px;
      line-height: 2.5rem;
      text-transform: uppercase;
      color: #ffffff; }
      .section-slides.section--titled .section__nav li.active {
        color: #999999; }
      .section-slides.section--titled .section__nav li:before {
        display: none; }
  .section-slides section {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    height: calc(100vh - var(--vh-offset, 0px));
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    @media (max-width: 768px) {
      .section-slides section {
        height: calc(100vh - 30px - 64px);
        height: calc(100vh - var(--vh-offset, 0px) - 30px - 64px); } }
    .section-slides section > h1 {
      position: absolute;
      top: 80px;
      left: 170px;
      width: 360px; }
      @media (max-width: 768px) {
        .section-slides section > h1 {
          left: 10vw; } }
    .section-slides section.dark .section__intro {
      color: #000000; }
    .section-slides section .section__intro {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      box-sizing: border-box;
      margin-top: 20vh;
      color: white; }
      .section-slides section .section__intro::after {
        clear: both;
        content: "";
        display: block; }
      .section-slides section .section__intro > *:not(style):not(script) {
        box-sizing: border-box; }
        .section-slides section .section__intro > *:not(style):not(script):nth-child(1n+1) {
          width: calc(58.3333333333% - 47.5px);
          float: left;
          margin-left: 30px;
          left: calc(8.3333333333% - 32.5px + 30px);
          position: relative; }
        .section-slides section .section__intro > *:not(style):not(script):not(:nth-child(1n+1)) {
          clear: none; }
        .section-slides section .section__intro > *:not(style):not(script):nth-child(1n+1) {
          clear: both; }
      @media (max-width: 768px) {
        .section-slides section .section__intro {
          margin-right: auto;
          margin-left: auto;
          max-width: 1170px;
          box-sizing: border-box;
          margin-top: 10vh; }
          .section-slides section .section__intro::after {
            clear: both;
            content: "";
            display: block; }
          .section-slides section .section__intro > *:not(style):not(script) {
            box-sizing: border-box; }
            .section-slides section .section__intro > *:not(style):not(script):nth-child(1n+1) {
              width: calc(75% - 52.5px);
              float: left;
              margin-left: 30px;
              left: calc(0% - 30px + 30px);
              position: relative; }
            .section-slides section .section__intro > *:not(style):not(script):not(:nth-child(1n+1)) {
              clear: none; }
            .section-slides section .section__intro > *:not(style):not(script):nth-child(1n+1) {
              clear: both; } }
    .section-slides section .section__half-box {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -60%;
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      box-sizing: border-box;
      display: flex;
      width: 60%;
      transition: all 250ms ease;
      color: #ffffff;
      background-color: #373239;
      align-items: center; }
      .section-slides section .section__half-box::after {
        clear: both;
        content: "";
        display: block; }
      .section-slides section .section__half-box > *:not(style):not(script) {
        box-sizing: border-box; }
        .section-slides section .section__half-box > *:not(style):not(script):nth-child(1n+1) {
          width: calc(73.75% - 52.125px);
          float: left;
          margin-left: 30px;
          left: calc(8.3333333333% - 32.5px + 30px);
          position: relative; }
        .section-slides section .section__half-box > *:not(style):not(script):not(:nth-child(1n+1)) {
          clear: none; }
        .section-slides section .section__half-box > *:not(style):not(script):nth-child(1n+1) {
          clear: both; }
      @media (max-width: 768px) {
        .section-slides section .section__half-box {
          margin-right: auto;
          margin-left: auto;
          max-width: 1170px;
          box-sizing: border-box;
          padding-top: 64px;
          padding-bottom: 64px;
          display: block;
          overflow: auto; }
          .section-slides section .section__half-box::after {
            clear: both;
            content: "";
            display: block; }
          .section-slides section .section__half-box > *:not(style):not(script) {
            box-sizing: border-box; }
            .section-slides section .section__half-box > *:not(style):not(script):nth-child(1n+1) {
              width: calc(83.3333333333% - 55px);
              float: left;
              margin-left: 30px;
              left: calc(0% - 30px + 30px);
              position: relative; }
            .section-slides section .section__half-box > *:not(style):not(script):not(:nth-child(1n+1)) {
              clear: none; }
            .section-slides section .section__half-box > *:not(style):not(script):nth-child(1n+1) {
              clear: both; } }
      @media (max-width: 768px) {
        .section-slides section .section__half-box {
          margin-right: auto;
          margin-left: auto;
          max-width: 1170px;
          box-sizing: border-box;
          padding-top: 24px;
          padding-bottom: 24px; }
          .section-slides section .section__half-box::after {
            clear: both;
            content: "";
            display: block; }
          .section-slides section .section__half-box > *:not(style):not(script) {
            box-sizing: border-box; }
            .section-slides section .section__half-box > *:not(style):not(script):nth-child(1n+1) {
              width: calc(100% - 60px);
              float: left;
              margin-left: 30px;
              left: calc(0% - 30px + 30px);
              position: relative; }
            .section-slides section .section__half-box > *:not(style):not(script):not(:nth-child(1n+1)) {
              clear: none; }
            .section-slides section .section__half-box > *:not(style):not(script):nth-child(1n+1) {
              clear: both; } }
      .section-slides section .section__half-box.half-box--white {
        color: #000000;
        background-color: #ffffff; }
      .section-slides section .section__half-box.active {
        left: 0; }
        @media (max-width: 768px) {
          .section-slides section .section__half-box.active {
            width: 100%; } }
    .section-slides section .info-box {
      position: absolute;
      top: 75px;
      right: 0;
      padding: 73px 95px 80px;
      box-sizing: border-box;
      width: 625px;
      background-color: #ffffff; }
      @media (max-width: 768px) {
        .section-slides section .info-box {
          padding: 32px 64px 40px;
          width: 500px; } }
      @media (max-width: 768px) {
        .section-slides section .info-box {
          padding: 30px;
          top: auto;
          bottom: 0;
          width: 100%; } }
      @media (max-width: 768px) {
        .section-slides section .info-box h1 {
          font-size: 2.1rem;
          line-height: 2.5rem; } }
      @media (max-width: 768px) {
        .section-slides section .info-box p {
          font-size: 1.5rem;
          line-height: 2.1rem; } }
    .section-slides section .slider__nav {
      padding: 18px 16px;
      display: none;
      align-items: center; }
      .section-slides section .slider__nav .prev,
      .section-slides section .slider__nav .next {
        margin-right: 8px;
        margin-left: 8px;
        color: #333333; }
        .section-slides section .slider__nav .prev:before,
        .section-slides section .slider__nav .next:before {
          font-family: 'fontello';
          font-weight: normal;
          font-style: normal;
          font-variant: normal;
          line-height: 1;
          content: "";
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
      .section-slides section .slider__nav .prev {
        transform: scaleX(-1); }
      .section-slides section .slider__nav .slider__dots {
        display: flex;
        list-style: none;
        align-items: center;
        flex-grow: 1;
        justify-content: space-around; }
        .section-slides section .slider__nav .slider__dots .slider__dot {
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 16px;
          background-color: #e5e5e5; }
          .section-slides section .slider__nav .slider__dots .slider__dot.active {
            background-color: #b3b3b3; }
      @media (max-width: 768px) {
        .section-slides section .slider__nav {
          display: flex; } }
    .section-slides section .details-box {
      position: absolute;
      top: 50%;
      right: 0;
      padding-right: 36px;
      padding-left: 36px;
      z-index: 1;
      display: flex;
      visibility: hidden;
      max-width: 405px;
      transition: 250ms;
      transform: translateY(-50%);
      opacity: 0;
      background-color: #fff9ec;
      align-items: center; }
      @media (max-width: 768px) {
        .section-slides section .details-box {
          padding-top: 24px;
          padding-bottom: 24px;
          position: initial;
          visibility: visible;
          height: auto !important;
          transform: none !important;
          opacity: 1; } }
      .section-slides section .details-box.hide {
        transform: translateY(-50%) translateX(100%); }
        .section-slides section .details-box.hide [href='#show-hide']:before {
          content: 'Read More'; }
      .section-slides section .details-box [href='#show-hide'] {
        position: absolute;
        right: 100%;
        bottom: 100%;
        transform: rotateZ(-90deg);
        transform-origin: bottom right;
        text-align: center;
        text-decoration: none;
        color: #000000;
        background-color: #b1dee6; }
        .section-slides section .details-box [href='#show-hide']:before {
          content: 'Close'; }
      .section-slides section .details-box.show {
        visibility: visible;
        opacity: 1; }
    .section-slides section .slider,
    .section-slides section .thumbnails {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap; }
      .section-slides section .slider figure,
      .section-slides section .thumbnails figure {
        display: inline-block; }
    .section-slides section .slider .slider__wrapper {
      overflow: hidden;
      height: calc(100vh - 50px);
      height: calc(100vh - var(--vh-offset, 0px) - 50px); }
    .section-slides section .slider .arrow__wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      cursor: none; }
      @media (max-width: 1024px) {
        .section-slides section .slider .arrow__wrapper {
          display: none; } }
      .section-slides section .slider .arrow__wrapper .arrow {
        position: absolute;
        transition: transform 250ms, opacity 250ms;
        pointer-events: none; }
        .section-slides section .slider .arrow__wrapper .arrow.light {
          color: #ffffff; }
        .section-slides section .slider .arrow__wrapper .arrow.dark {
          color: #000000; }
        .section-slides section .slider .arrow__wrapper .arrow.top {
          transform: translateX(-50%) translateY(-50%) rotateZ(-90deg); }
        .section-slides section .slider .arrow__wrapper .arrow.right {
          transform: translateX(-50%) translateY(-50%) rotateZ(0deg); }
        .section-slides section .slider .arrow__wrapper .arrow.bottom {
          transform: translateX(-50%) translateY(-50%) rotateZ(90deg); }
        .section-slides section .slider .arrow__wrapper .arrow.left {
          transform: translateX(-50%) translateY(-50%) rotateZ(-180deg); }
        .section-slides section .slider .arrow__wrapper .arrow.hide {
          opacity: 0; }
        .section-slides section .slider .arrow__wrapper .arrow:before {
          font-family: 'fontello';
          font-weight: normal;
          font-style: normal;
          font-variant: normal;
          line-height: 1;
          content: "";
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 2.5rem; }
    .section-slides section .slider figure {
      width: 100%;
      height: calc(100vh - 50px);
      height: calc(100vh - var(--vh-offset, 0px) - 50px);
      background-position: center;
      background-size: cover; }
      .section-slides section .slider figure figcaption {
        display: none; }
    .section-slides section .thumbnails {
      position: absolute;
      right: 0;
      bottom: 50px;
      left: 0;
      z-index: 1;
      overflow-x: auto;
      overflow-y: hidden;
      height: 0;
      transition: 250ms;
      background-color: #373239; }
      @media (max-width: 768px) {
        .section-slides section .thumbnails {
          display: none; } }
      .section-slides section .thumbnails.show {
        height: 151px; }
      .section-slides section .thumbnails .close {
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 1.2rem;
        line-height: 1;
        padding: 4px; }
        .section-slides section .thumbnails .close:before {
          font-family: 'fontello';
          font-weight: normal;
          font-style: normal;
          font-variant: normal;
          line-height: 1;
          content: "";
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: #ffffff; }
      .section-slides section .thumbnails figure {
        margin: 32px 35px;
        font-size: 0;
        line-height: 0; }
        .section-slides section .thumbnails figure figcaption {
          display: none; }
    .section-slides section [href='#view-all'] {
      position: absolute;
      bottom: 58px;
      left: 50%;
      transform: translateX(-50%);
      text-decoration: none;
      color: #ffffff; }
      .section-slides section [href='#view-all'].dark {
        font-weight: 800;
        color: #000000; }
      @media (max-width: 768px) {
        .section-slides section [href='#view-all'] {
          display: none; } }
    .section-slides section.active {
      top: 0;
      transition: all 250ms ease; }
    .section-slides section.allow-scroll {
      overflow-y: auto; }
    .section-slides section.out-up {
      top: calc(-100% - 50px);
      transition: all 250ms ease; }
    .section-slides section.out-down {
      transition: all 250ms ease; }

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

.trainers {
  margin-bottom: 100px; }
  @media (max-width: 768px) {
    .trainers {
      margin-bottom: 20px; } }
  .trainers .trainers__wrapper .button {
    margin-top: 50px;
    margin-bottom: 110px; }
  .trainers .trainers__wrapper .trainers__listing {
    display: flex;
    gap: 30px;
    flex-wrap: wrap; }
    .trainers .trainers__wrapper .trainers__listing .trainer {
      display: flex;
      flex-direction: column;
      width: 100%; }
      @media (min-width: 768px) {
        .trainers .trainers__wrapper .trainers__listing .trainer {
          width: calc(50% - (30px / 2)); } }
      @media (min-width: 1268px) {
        .trainers .trainers__wrapper .trainers__listing .trainer {
          width: calc(33.3333% - ((30px * 2 )/ 3)); } }
      .trainers .trainers__wrapper .trainers__listing .trainer img {
        width: auto; }
      .trainers .trainers__wrapper .trainers__listing .trainer h1 {
        margin-top: 40px;
        margin-bottom: 0;
        font-size: 2rem; }
      .trainers .trainers__wrapper .trainers__listing .trainer p {
        margin-bottom: 0; }
      .trainers .trainers__wrapper .trainers__listing .trainer a {
        font-size: 1.6rem;
        display: flex;
        width: fit-content;
        text-decoration: none;
        color: #f85650;
        gap: 11px;
        align-items: center; }
        .trainers .trainers__wrapper .trainers__listing .trainer a:hover {
          color: rgba(248, 86, 80, 0.6); }
        .trainers .trainers__wrapper .trainers__listing .trainer a .plus:before {
          font-family: 'fontello';
          font-weight: normal;
          font-style: normal;
          font-variant: normal;
          line-height: 1;
          content: "";
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 1.6rem;
          display: inline-block;
          margin-left: 12px;
          transform: translateY(-2px) rotateZ(45deg); }
      .trainers .trainers__wrapper .trainers__listing .trainer .trainer__info {
        display: none; }

.trainer__name {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
  justify-content: space-between; }

.image-carousel {
  position: relative; }
  .image-carousel .image-wrapper {
    position: relative; }
    .image-carousel .image-wrapper > .carousel-image:not(:first-child) {
      position: absolute;
      inset: 0; }
    .image-carousel .image-wrapper > .carousel-image {
      overflow: hidden;
      max-height: 500px;
      transition: 250ms;
      opacity: 0; }
      .image-carousel .image-wrapper > .carousel-image.active {
        opacity: 1; }

.image__wrapper {
  overflow: hidden;
  padding-bottom: 192px; }

.carousel-image {
  transition: 250ms;
  /* Initially hide all images */ }
  .carousel-image img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    object-position: top; }
  .carousel-image.slide-in-left {
    animation: slideInLeft 250ms forwards; }
  .carousel-image.slide-in-right {
    animation: slideInRight 250ms forwards; }

.carousel__arrows {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between; }
  @media (min-width: 940px) {
    .carousel__arrows {
      padding: 10px 40px; } }

.carousel__prev_arrow,
.carousel__next_arrow {
  bottom: 0;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Stroke 1' d='M5.21057 15.1948L27.7551 15.1948V13.4448L5.21057 13.4448V15.1948Z' fill='%23D9D9D9'/%3E%3Cpath id='Stroke 3' d='M3.7504 14.3197L11.399 21.9684L12.6338 20.7283L6.19848 14.3207L12.6339 7.91114L11.399 6.67114L3.7504 14.3197Z' fill='%23D9D9D9'/%3E%3C/svg%3E%0A"); }
  .carousel__prev_arrow:hover,
  .carousel__next_arrow:hover {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Stroke 1' d='M5.21057 15.1948L27.7551 15.1948V13.4448L5.21057 13.4448V15.1948Z' fill='%23f85851'/%3E%3Cpath id='Stroke 3' d='M3.7504 14.3197L11.399 21.9684L12.6338 20.7283L6.19848 14.3207L12.6339 7.91114L11.399 6.67114L3.7504 14.3197Z' fill='%23f85851'/%3E%3C/svg%3E%0A"); }

.carousel__next_arrow {
  transform: rotate(180deg); }

.toggle-bio {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  padding: 10px 25px;
  cursor: pointer;
  transition: 250ms;
  letter-spacing: .72px;
  color: #000000;
  border: none;
  border-radius: 60px;
  background-color: #b1dee6; }

.trainer-bio {
  overflow: hidden;
  max-height: 0;
  transition: 250ms ease-in-out;
  background-color: #fff9ec; }
  .trainer-bio[data-active='true'] {
    max-height: 400px;
    transition: 250ms ease-in-out; }
  .trainer-bio .bio__container {
    padding: 20px; }
    @media (min-width: 940px) {
      .trainer-bio .bio__container {
        padding: 40px; } }
  .trainer-bio p {
    display: block;
    overflow-y: auto;
    max-height: 200px;
    padding-right: 40px;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    @media (min-width: 350px) {
      .trainer-bio p {
        max-height: 250px; } }
    @media (min-width: 400px) {
      .trainer-bio p {
        max-height: 300px; } }
    @media (min-width: 500px) {
      .trainer-bio p {
        max-height: 330px; } }
    .trainer-bio p::-webkit-scrollbar {
      width: 5px; }
    .trainer-bio p::-webkit-scrollbar-track {
      background: #b0afaf; }
    .trainer-bio p::-webkit-scrollbar-thumb {
      background: #373239; }
    .trainer-bio p::-webkit-scrollbar-thumb:hover {
      background: #373239; }

.toggle-bio:hover {
  background-color: #e1f3f5; }

.sub-navigation + :not(.copy) {
  margin-top: 75px; }

.sub-navigation a {
  margin-right: 20px;
  padding-right: 15px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000000;
  border-right: 1px solid black; }
  .sub-navigation a.active {
    color: #b3b3b3; }
  .sub-navigation a:hover {
    color: #f85650; }
  .sub-navigation a:last-child {
    border: none; }

.featured-image {
  display: flex;
  margin-bottom: 100px; }
  @media (max-width: 768px) {
    .featured-image {
      display: block; } }
  .featured-image > figure {
    line-height: 0; }
    @media (min-width: 769px) {
      .featured-image > figure {
        display: none; } }
  .featured-image .featured-image__wrapper {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    box-sizing: border-box;
    display: flex;
    width: 50%;
    margin: 135px auto;
    margin-right: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    color: #ffffff;
    background-color: #f85650;
    justify-content: center; }
    .featured-image .featured-image__wrapper::after {
      clear: both;
      content: "";
      display: block; }
    .featured-image .featured-image__wrapper > *:not(style):not(script) {
      box-sizing: border-box; }
      .featured-image .featured-image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        width: calc(83.3333333333% - 55px);
        float: left;
        margin-left: 30px; }
      .featured-image .featured-image__wrapper > *:not(style):not(script):not(:nth-child(1n+1)) {
        clear: none; }
      .featured-image .featured-image__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
    @media (max-width: 768px) {
      .featured-image .featured-image__wrapper {
        margin-right: auto;
        margin-left: auto;
        max-width: 1170px;
        box-sizing: border-box;
        margin-top: 0;
        margin-bottom: 0;
        width: 100%; }
        .featured-image .featured-image__wrapper::after {
          clear: both;
          content: "";
          display: block; }
        .featured-image .featured-image__wrapper > *:not(style):not(script) {
          box-sizing: border-box; }
          .featured-image .featured-image__wrapper > *:not(style):not(script):nth-child(1n+1) {
            width: calc(83.3333333333% - 55px);
            float: left;
            margin-left: 30px; }
          .featured-image .featured-image__wrapper > *:not(style):not(script):not(:nth-child(1n+1)) {
            clear: none; }
          .featured-image .featured-image__wrapper > *:not(style):not(script):nth-child(1n+1) {
            clear: both; } }
    .featured-image .featured-image__wrapper div .button {
      padding: 1px 21px;
      transition: 375ms ease-in;
      background-color: #ffffff;
      background-image: linear-gradient(90deg, rgba(177, 222, 230, 0), #b1dee6, #ffe5a4);
      background-position: -100% 0;
      background-size: 200% 100%; }
      .featured-image .featured-image__wrapper div .button:hover {
        transition-timing-function: ease-out;
        background-position: 100% 0; }

.accordion {
  margin-bottom: 75px; }
  @media (max-width: 768px) {
    .accordion {
      margin-bottom: 40px; } }
  .accordion:first-child {
    margin-top: 75px; }
    @media (max-width: 768px) {
      .accordion:first-child {
        margin-top: 40px; } }
  .accordion .accordion__wrapper {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); }
    .accordion .accordion__wrapper > ul {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0;
      list-style-type: none; }
      .accordion .accordion__wrapper > ul > li .accordion__title {
        padding: 22px 32px;
        position: relative;
        background-image: linear-gradient(90deg, #b1dee6, #ffe5a4); }
        @media (max-width: 768px) {
          .accordion .accordion__wrapper > ul > li .accordion__title {
            padding-top: 11px;
            padding-bottom: 11px;
            font-size: .8rem;
            margin-left: 0;
            padding-right: 16px;
            padding-left: 16px; } }
        @media (max-width: 320px) {
          .accordion .accordion__wrapper > ul > li .accordion__title {
            padding-right: 3px;
            padding-left: 3px; } }
        .accordion .accordion__wrapper > ul > li .accordion__title h1 {
          position: relative;
          z-index: 1;
          display: inline-block;
          margin-bottom: 0;
          margin-left: 38px;
          vertical-align: middle; }
          @media (max-width: 768px) {
            .accordion .accordion__wrapper > ul > li .accordion__title h1 {
              margin-left: 0; } }
        .accordion .accordion__wrapper > ul > li .accordion__title:hover:after {
          opacity: 1; }
        .accordion .accordion__wrapper > ul > li .accordion__title:after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          content: '';
          transition: 500ms;
          opacity: 0;
          background-image: linear-gradient(90deg, #b1dee6, rgba(177, 222, 230, 0));
          background-repeat: no-repeat; }
        .accordion .accordion__wrapper > ul > li .accordion__title:before {
          font-family: 'fontello';
          font-weight: normal;
          font-style: normal;
          font-variant: normal;
          line-height: 1;
          content: "";
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 15px;
          line-height: 55px;
          position: relative;
          z-index: 1;
          display: inline-block;
          width: 55px;
          height: 55px;
          transition: 250ms;
          transform: rotate(90deg);
          text-align: center;
          vertical-align: middle;
          color: #b3b3b3;
          border-radius: 55px;
          background-color: #ffffff; }
          @media (max-width: 768px) {
            .accordion .accordion__wrapper > ul > li .accordion__title:before {
              transform: rotate(90deg) scale(0.5); } }
      .accordion .accordion__wrapper > ul > li .accordion__content {
        overflow: hidden;
        height: 0;
        transition: 250ms;
        background-color: #f2f2f2; }
        .accordion .accordion__wrapper > ul > li .accordion__content iframe {
          border: none; }
        .accordion .accordion__wrapper > ul > li .accordion__content .accordion__copy {
          padding: 24px 32px; }
      .accordion .accordion__wrapper > ul > li.show .accordion__title:before {
        transform: rotate(-90deg); }
        @media (max-width: 768px) {
          .accordion .accordion__wrapper > ul > li.show .accordion__title:before {
            transform: rotate(-90deg) scale(0.5); } }
      .accordion .accordion__wrapper > ul > li.show .accordion__title:after {
        opacity: 1;
        background-color: #b1dee6; }
      .accordion .accordion__wrapper > ul > li.show .accordion__content {
        overflow: auto;
        height: auto; }

.classes .classes__wrapper div > h1 {
  margin-top: 15px; }

.classes .classes__wrapper div > a {
  margin-top: 40px;
  margin-bottom: 135px; }

.classes .classes__wrapper div > p {
  margin-bottom: 85px; }

.classes .classes__wrapper .classes__listing .listing__wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  box-sizing: border-box;
  margin-bottom: -30px;
  margin-right: -30px;
  margin-left: -30px; }
  .classes .classes__wrapper .classes__listing .listing__wrapper::after {
    clear: both;
    content: "";
    display: block; }
  .classes .classes__wrapper .classes__listing .listing__wrapper > *:not(style):not(script) {
    box-sizing: border-box; }
    .classes .classes__wrapper .classes__listing .listing__wrapper > *:not(style):not(script):nth-child(1n+1) {
      width: calc(100% - 60px);
      float: left;
      margin-left: 30px;
      margin-bottom: 30px; }
    .classes .classes__wrapper .classes__listing .listing__wrapper > *:not(style):not(script):not(:nth-child(1n+1)) {
      clear: none; }
    .classes .classes__wrapper .classes__listing .listing__wrapper > *:not(style):not(script):nth-child(1n+1) {
      clear: both; }

.classes .classes__wrapper .classes__listing .class {
  padding-bottom: 26px;
  border-bottom: 1px solid #f2f2f2; }
  .classes .classes__wrapper .classes__listing .class:first-child {
    padding-top: 26px;
    border-top: 1px solid #f2f2f2; }
  .classes .classes__wrapper .classes__listing .class .class__info p {
    margin-bottom: 1.6rem; }
    .classes .classes__wrapper .classes__listing .class .class__info p:last-child {
      margin-bottom: 0; }
  .classes .classes__wrapper .classes__listing .class .class__info ul {
    display: inline-flex;
    padding-left: 0; }
    .classes .classes__wrapper .classes__listing .class .class__info ul li {
      margin-right: 50px; }
      .classes .classes__wrapper .classes__listing .class .class__info ul li a {
        transition: 250ms;
        color: #f85650; }
        .classes .classes__wrapper .classes__listing .class .class__info ul li a:hover {
          color: rgba(248, 86, 80, 0.6); }
        .classes .classes__wrapper .classes__listing .class .class__info ul li a.button {
          padding-right: 14px;
          padding-left: 14px;
          line-height: 2.1rem;
          transition: 500ms ease-in;
          color: #000000;
          border: 2px solid #e5e5e5;
          border-radius: 30px;
          background-color: transparent;
          background-image: linear-gradient(90deg, rgba(229, 229, 229, 0), #e5e5e5, #e5e5e5);
          background-position: -100% 0;
          background-size: 200% 100%; }
          .classes .classes__wrapper .classes__listing .class .class__info ul li a.button:hover {
            transition-timing-function: ease-out;
            background-position: 100% 0; }
  .classes .classes__wrapper .classes__listing .class h2 {
    font-size: 2.4rem; }
    @media (max-width: 768px) {
      .classes .classes__wrapper .classes__listing .class h2 {
        font-size: 2.1rem;
        line-height: 2.5rem; } }

/* Firefox */
_:-moz-tree-row(hover),
.button__wrapper {
  margin-top: -100px; }

/* Edge & IE only */
_:-ms-lang(x),
.button__wrapper {
  margin-top: -100px; }

.callout {
  overflow: hidden; }
  .callout .callout__wrapper {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    box-sizing: border-box; }
    .callout .callout__wrapper::after {
      clear: both;
      content: "";
      display: block; }
    .callout .callout__wrapper > *:not(style):not(script) {
      box-sizing: border-box; }
      .callout .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
        width: calc(75% - 52.5px);
        float: left;
        margin-left: 30px;
        margin-left: calc(25% - 37.5px + 60px); }
      .callout .callout__wrapper > *:not(style):not(script):not(:nth-child(1n+1)) {
        clear: none; }
      .callout .callout__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
    .callout .callout__wrapper > div {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      box-sizing: border-box;
      padding-top: 65px;
      padding-bottom: 49px;
      position: relative; }
      .callout .callout__wrapper > div::after {
        clear: both;
        content: "";
        display: block; }
      .callout .callout__wrapper > div > *:not(style):not(script) {
        box-sizing: border-box; }
        .callout .callout__wrapper > div > *:not(style):not(script):nth-child(1n+1) {
          width: calc(77.7777777778% - 53.3333333333px);
          float: left;
          margin-left: 30px;
          margin-left: calc(22.2222222222% - 36.6666666667px + 60px); }
        .callout .callout__wrapper > div > *:not(style):not(script):not(:nth-child(1n+1)) {
          clear: none; }
        .callout .callout__wrapper > div > *:not(style):not(script):nth-child(1n+1) {
          clear: both; }
      .callout .callout__wrapper > div:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        content: '';
        background-image: linear-gradient(90deg, #b1dee6, #ffe5a4); }
      .callout .callout__wrapper > div > p {
        text-transform: uppercase; }
    .callout .callout__wrapper .callout__featured-text p em {
      font-size: 4rem;
      font-style: normal;
      vertical-align: super; }

.portal {
  margin-top: 100px;
  margin-bottom: 100px; }
  .portal .portal__wrapper {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    box-sizing: border-box; }
    .portal .portal__wrapper::after {
      clear: both;
      content: "";
      display: block; }
    .portal .portal__wrapper > *:not(style):not(script) {
      box-sizing: border-box; }
      .portal .portal__wrapper > *:not(style):not(script):nth-child(1n+1) {
        width: calc(66.6666666667% - 50px);
        float: left;
        margin-left: 30px;
        margin-left: calc(16.6666666667% - 35px + 60px); }
      .portal .portal__wrapper > *:not(style):not(script):not(:nth-child(1n+1)) {
        clear: none; }
      .portal .portal__wrapper > *:not(style):not(script):nth-child(1n+1) {
        clear: both; }
    .portal .portal__wrapper .button {
      margin-bottom: 16px; }
    .portal .portal__wrapper .portal__listing {
      margin-top: 55px; }
  .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper {
    margin-right: -30px;
    margin-left: -30px;
    max-width: 1170px;
    box-sizing: border-box;
    margin-bottom: -30px; }
    .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper::after {
      clear: both;
      content: "";
      display: block; }
    .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper > *:not(style):not(script) {
      box-sizing: border-box; }
      .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper > *:not(style):not(script):nth-child(2n+1) {
        width: calc(50% - 45px);
        float: left;
        margin-left: 30px;
        margin-bottom: 30px; }
      .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper > *:not(style):not(script):nth-child(2n+2) {
        width: calc(50% - 45px);
        float: left;
        margin-left: 30px;
        margin-bottom: 30px; }
      .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper > *:not(style):not(script):not(:nth-child(2n+1)) {
        clear: none; }
      .portal.portal--two-up .portal__wrapper .portal__listing .listing__wrapper > *:not(style):not(script):nth-child(2n+1) {
        clear: both; }
  .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper {
    margin-right: -30px;
    margin-left: -30px;
    max-width: 1170px;
    box-sizing: border-box;
    margin-bottom: -30px; }
    .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper::after {
      clear: both;
      content: "";
      display: block; }
    .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper > *:not(style):not(script) {
      box-sizing: border-box; }
      .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper > *:not(style):not(script):nth-child(3n+1) {
        width: calc(25% - 37.5px);
        float: left;
        margin-left: 30px;
        margin-bottom: 30px;
        margin-left: calc(0% - 30px + 60px); }
      .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper > *:not(style):not(script):nth-child(3n+2) {
        width: calc(25% - 37.5px);
        float: left;
        margin-left: 30px;
        margin-bottom: 30px;
        margin-left: calc(12.5% - 33.75px + 60px); }
      .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper > *:not(style):not(script):nth-child(3n+3) {
        width: calc(25% - 37.5px);
        float: left;
        margin-left: 30px;
        margin-bottom: 30px;
        margin-left: calc(12.5% - 33.75px + 60px); }
      .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper > *:not(style):not(script):not(:nth-child(3n+1)) {
        clear: none; }
      .portal.portal--three-up .portal__wrapper .portal__listing .listing__wrapper > *:not(style):not(script):nth-child(3n+1) {
        clear: both; }

.button {
  padding: 3px 21px;
  display: inline-block;
  transition: 250ms;
  text-decoration: none;
  color: #000000;
  border-radius: 32px;
  background-color: #b1dee6;
  background-image: linear-gradient(90deg, rgba(255, 229, 164, 0) 25%, #ffe5a4);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 0 100%; }
  .button:hover {
    background-size: 100% 100%; }

.become-a-member {
  position: fixed;
  top: 0;
  right: 85px;
  padding-right: 26px;
  padding-left: 26px;
  z-index: 1000;
  transition: 250ms ease-in;
  text-decoration: none;
  color: #ffffff;
  background-color: #f85650;
  background-image: linear-gradient(90deg, transparent, transparent, rgba(0, 0, 0, 0.45));
  background-repeat: no-repeat;
  background-position: -100% 0;
  background-size: 200% 100%; }
  .become-a-member:hover {
    transition-timing-function: ease-out;
    background-position: 100% 0; }
  @media (max-width: 768px) {
    .become-a-member {
      right: 0;
      left: 0; }
      .become-a-member ~ main {
        min-height: calc(100vh - var(--vh-offset, 0px) - 30px);
        margin-top: 30px; } }

/* Safari only */
_:-webkit-full-screen:not(:root:root),
.become-a-member {
  font-weight: 900; }

/* Edge & IE only */
_:-ms-lang(x),
.become-a-member {
  font-weight: 900; }

/*# sourceMappingURL=style.css.map */